.swiper-pagination {
  position: initial;
  margin-top: 40px;
}

.swiper-pagination-bullet {
  background-color: #eff2f5;
  width: 10px;
  height: 10px;
  opacity: 1;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #00a3ff;
}
